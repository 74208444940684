import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { appInsights } from "@/lib/appInsights";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { languageSettingGlobal } from "./state";

const ATTEMPT_RESET_TIME_IN_HOURS = {
   2: 0.083333366667, // 5 min
   4: 0.083333366667, // 5 min
   6: 0.5, // 30 min
   8: 1, // 1 hour
   10: 5, // 5 hours
   12: 72, // 72 hours
   14: 1000, // 1000 hours
};

const dictionary = {
   thanksForInterest: {
      en: "Thank you for your interest in Ailegis!",
      it: "Grazie per il tuo interesse in Ailegis!",
      fr: "Merci pour votre intérêt pour Ailegis !",
      de: "Danke für dein Interesse an Ailegis!",
   },
   timeUntilNextAttempt: {
      en: "Time until next attempt:",
      it: "Tempo fino al prossimo tentativo:",
      fr: "Temps jusqu'à la prochaine tentative:",
      de: "Zeit bis zum nächsten Versuch:",
   },
   reachedFreeLimit: {
      en: "You have reached the number of free requests. Enter your email to continue accessing Ailegis.",
      it: "Hai raggiunto il numero di richieste gratuite. Inserisci il tuo indirizzo email per continuare ad accedere a Ailegis.",
      fr: "Vous avez atteint le nombre de demandes gratuites. Saisissez votre adresse e-mail pour continuer à accéder à Ailegis.",
      de: "Du hast die Anzahl frei verfügbarer Anfragen erreicht. Gib deine E-Mail Adresse an, um weiterhin Zugriff auf Ailegis zu haben.",
   },
   emailLabel: {
      en: "Email",
      it: "Email",
      fr: "Email",
      de: "Email",
   },
   messageLabel: {
      en: "Message",
      it: "Messaggio",
      fr: "Message",
      de: "Nachricht",
   },
   sendRequest: {
      en: "Send Request",
      it: "Invia richiesta",
      fr: "Envoyer la demande",
      de: "Anfrage senden",
   },
   messageSentSuccess: {
      en: "Message successfully sent",
      it: "Messaggio inviato con successo",
      fr: "Message envoyé avec succès",
      de: "Nachricht erfolgreich gesendet",
   },
   alreadyHaveLogin: {
      en: "Already have a login?",
      it: "Hai già un account?",
      fr: "Vous avez déjà un compte?",
      de: "Du hast bereits ein Login?",
   },
   loginButton: {
      en: "Login",
      it: "Accedi",
      fr: "Connexion",
      de: "Login",
   },
};

export function getLocalStorageItem(key: string, defaultValue: any) {
   if (typeof window !== "undefined") {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
   } else {
      return defaultValue;
   }
}

export function setLocalStorageItem(key: string, value: any) {
   if (typeof window !== "undefined") {
      localStorage.setItem(key, JSON.stringify(value));
   }
}

export function calculateTimeDifferenceInHours(date1: Date, date2: Date): number {
   return (date2.getTime() - date1.getTime()) / (1000 * 60 * 60);
}

export function getTimeToWaitInHours(attemptsTot: number): number {
   for (const [limit, time] of Object.entries(ATTEMPT_RESET_TIME_IN_HOURS)) {
      if (attemptsTot <= parseInt(limit)) {
         return time;
      }
   }
   return 0;
}

export function incrementLocalStorageItem(key: string): void {
   const value = getLocalStorageItem(key, 0);
   setLocalStorageItem(key, value + 1);
}

export default function DemoBlock({ closeBlock, session }: { closeBlock: () => void; session: any }) {
   const [trailModeEmail, setTrailModeEmail] = useState("");
   const [trailModeMessage, setTrailModeMessage] = useState("");
   const [timeLeft, setTimeLeft] = useState<number | null>(null);
   const { toast } = useToast();
   const [lang, setLang] = useRecoilState(languageSettingGlobal);

   useEffect(() => {
      const attemptsTot = getLocalStorageItem("attempts_tot", 0);
      const lastDate = new Date(getLocalStorageItem("last_date", Date.now()));
      const currentDate = new Date();
      const timeToWaitInHour = getTimeToWaitInHours(attemptsTot);
      const timeDifferenceInHours = calculateTimeDifferenceInHours(lastDate, currentDate);

      const remainingTime = timeToWaitInHour - timeDifferenceInHours;
      if (remainingTime > 0) {
         setTimeLeft(remainingTime * 3600); // convert to seconds
      } else {
         setTimeLeft(null);
      }

      const interval = setInterval(() => {
         setTimeLeft((prevTimeLeft) => {
            if (prevTimeLeft && prevTimeLeft > 1) {
               return prevTimeLeft - 1;
            } else {
               clearInterval(interval);
               return null;
            }
         });
      }, 1000);

      return () => clearInterval(interval);
   }, []);

   const formatTimeLeft = (seconds: number) => {
      const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
      const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
      const secs = String(Math.floor(seconds % 60)).padStart(2, "0");
      return `${hrs}:${mins}:${secs}`;
   };

   return (
      <div>
         <div className="absolute h-screen w-screen grid place-items-center backdrop-blur-sm top-0">
            <div className="border rounded bg-white p-4 flex flex-col gap-2 relative max-w-sm m-2">
               <div className="absolute top-0 right-0">
                  <Button
                     variant="ghost"
                     onClick={() => {
                        closeBlock();
                     }}
                     className="h-8 px-2 lg:px-3 m-2"
                  >
                     <Cross2Icon className="h-4 w-4" />
                  </Button>
               </div>

               <p className=" font-medium">{dictionary.thanksForInterest[lang]}</p>

               {timeLeft !== null && (
                  <p className=" font-semibold text-ailegis-600">
                     {dictionary.timeUntilNextAttempt[lang]} {formatTimeLeft(timeLeft)}
                  </p>
               )}

               <p>{dictionary.reachedFreeLimit[lang]}</p>

               <div className="grid w-full items-center gap-1.5">
                  <Label htmlFor="Email">{dictionary.emailLabel[lang]}</Label>
                  <Input id="Email" value={trailModeEmail} onChange={(e) => setTrailModeEmail(e.target.value)} />
               </div>
               <div className="grid w-full items-center gap-1.5">
                  <Label>{dictionary.messageLabel[lang]}</Label>
                  <Textarea value={trailModeMessage} onChange={(e) => setTrailModeMessage(e.target.value)} />
               </div>

               <Button
                  onClick={() => {
                     fetch(process.env.NEXT_PUBLIC_BACKEND_URL + "/contact", {
                        method: "POST",
                        headers: {
                           "Content-Type": "application/json",
                           Authorization: `Bearer ${session?.data?.accessToken}`,
                        },
                        body: JSON.stringify({
                           name: "",
                           mail: "no-reply@ailegis.ch",
                           message:
                              "Demo Message" +
                              "\n\n" +
                              "Email:\n" +
                              trailModeEmail +
                              "\n\n" +
                              "Message:\n" +
                              trailModeMessage,
                        }),
                     })
                        .then((response) => response.json())
                        .then((data) => {
                           console.log("Submission successful", data);
                           closeBlock();
                           toast({
                              description: dictionary.messageSentSuccess[lang],
                           });
                        })
                        .catch((error: any) => {
                           console.error("Submission failed", error);
                           appInsights.trackException({ exception: error });
                           closeBlock();
                        });
                  }}
               >
                  {dictionary.sendRequest[lang]}
               </Button>
               <Separator />

               <div className="flex flex-row items-center justify-between">
                  <p>{dictionary.alreadyHaveLogin[lang]}</p>
                  <a href="/login">
                     <Button variant="secondary">{dictionary.loginButton[lang]}</Button>
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}
