// Application Insights on Azure
// Mostly following https://medium.com/@nirbhayluthra/integrating-azure-application-insights-with-next-js-the-easy-way-afc83596afad
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const defaultBrowserHistory = {
   url: "",
   location: {
      pathname: "",
   },
   listen: () => {},
};
let browserHistory = defaultBrowserHistory;
if (typeof window !== "undefined") {
   browserHistory = { ...browserHistory, ...window.history };
   browserHistory.location.pathname = window.location.pathname;
}
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
   config: {
      connectionString: process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING || "",
      extensions: [reactPlugin],
      extensionConfig: {
         [reactPlugin.identifier]: { history: browserHistory },
      },
      maxAjaxCallsPerView: 1000,
   },
});

if (typeof window !== "undefined") {
   appInsights.loadAppInsights();
}

export { appInsights, reactPlugin };
